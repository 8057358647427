<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-plain">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.organizer') }}</div>
              </div>
            </template>

            <card body-classes="standard-card-body mt-3" class="shadow">
              <deal-date-in-progress-scheduler
                @onAppointmentClick="appointmentClick"
                ref="dealDateInProgressScheduler"
              />
            </card>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.index') }}</div>
              </div>
            </template>
            <deals-in-progress-datatable/>
          </tab-pane>
        </tabs>
      </div>
    </div>
    <modal bodyClasses="p-1" modalClasses="modal-lg" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ this.dealSelected | optional('registry.surname') }}
        {{ this.dealSelected | optional('registry.name') }}
        <badge
          v-if="this.dealSelected.status"
          :style="{backgroundColor: schedulerService.getDealStatusColor(dealSelected.status)}"
               class="text-uppercase">
          {{ $t('deal.status.' + this.dealSelected.status) }}
        </badge>
      </h5>
      <deal-modal-content :deal="dealSelected"/>
      <template slot="footer">
        <base-button
          v-if="isDealVisible"
          @click="$router.push({name: 'deals.in_progress.show', params: {id: dealSelected.id}})"
          link
          class="text-uppercase">
          {{ $t('common.open') }}
        </base-button>

      </template>
    </modal>
  </div>
</template>

<script>
import DealsInProgressDatatable from "@/pages/Deals/datatables/DealsInProgressDatatable";
import {mapGetters} from "vuex";
import Modal from "@/components/Modal";
import DealModalContent from "@/pages/Deals/components/DealModalContent";
import Deal from "@/models/deal";
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from "@/components";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import DealStatuses from "./resources/dealStatuses";
import Badge from "../../components/Badge";
import {schedulerService} from "@/util/scheduler-service";
import DealDateInProgressScheduler from "@/components/DealDateScheduler/DealDateInProgressScheduler";

export default {
  name: "IndexDealsInProgressPage",
  components: {
    DealDateInProgressScheduler,
    Badge,
    OctoIcon,
    DealModalContent,
    Modal,
    DealsInProgressDatatable,
    Tabs,
    TabPane,
  },
  data() {
    return {
      showModal: false,
      dealSelected: this.$_.cloneDeep(Deal),
      schedulerService: schedulerService
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    isDealVisible() {
      return this.dealSelected.status === DealStatuses.confirmed.value
        || this.dealSelected.status === DealStatuses.processing.value
        || this.dealSelected.status === DealStatuses.recall.value
        || this.dealSelected.status === DealStatuses.closed.value;
    }
  },
  methods: {

    appointmentClick: function (dateInfo) {

      const dealId = dateInfo?.deal?.id || null;
      if (!dealId) {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')})
        return;
      }

      this.$fullLoading.show();
      this.$api.get(endpoints.DEAL_SHOW.replace('{id}', dealId))
        .then((resp) => {
          this.dealSelected = resp?.data?.data || this.$_.cloneDeep(Deal);
          this.showModal = true;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')})
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
