<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-flush">
          <list-group-item-component :label="$t('fields.date')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="mb-0 flex-grow-1">
                <el-date-picker
                  type="date"
                  v-model="appointmentDay"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  :placeholder="$t('common.select_day')"
                  :picker-options="{firstDayOfWeek: 1}"
                />
              </base-input>
            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('fields.time_start')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="mb-0 flex-grow-1">
                <el-time-select
                  v-model="timeStart"
                  format="HH:mm"
                  value-format="HH:mm:ss"
                  :placeholder="$t('fields.time_start')"
                  :picker-options="pickerOptions"
                />
              </base-input>
            </template>
          </list-group-item-component>
          <list-group-item-component :label="$t('fields.time_end')" :label-width="labelWidth">
            <template slot="value">
              <base-input class="mb-0 flex-grow-1">
                <el-time-select
                  v-model="timeEnd"
                  format="HH:mm"
                  value-format="HH:mm:ss"
                  :placeholder="$t('fields.time_end')"
                  :picker-options="pickerOptions"
                />
              </base-input>
            </template>
          </list-group-item-component>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <base-text-area :label="$t('fields.notes')" v-model="text"/>
      </div>
    </div>
  </div>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import {TimeSelect, Select, Option, DatePicker} from "element-ui"
import BaseTextArea from "@/components/Inputs/BaseTextArea";

export default {
  name: "NewAppointmentBlockModal",
  components: {
    BaseTextArea,
    ListGroupItemComponent,
    ListGroupTitleSectionComponent,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
  },
  props: {
    event: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      timeStart: '',
      timeEnd: '',
      appointmentDay: '',
      text: '',
      labelWidth: 140
    }
  },
  mounted() {
    const dateObject = this.event?.cellData?.startDate
      ? this.$moment(this.event?.cellData?.startDate, 'YYYY-MM-DD HH:mm')
      : this.$moment(this.event?.appointmentData?.startDate, 'YYYY-MM-DD HH:mm');

    this.text = this.event?.appointmentData?.text || '';
    this.appointmentDay = dateObject.format('YYYY-MM-DD');
    this.timeStart = dateObject.format('HH:mm');
    this.timeEnd = this.event?.cellData?.startDate
      ? dateObject.add(30, 'm').format('HH:mm')
      : this.$moment(this.event?.appointmentData?.endDate, 'YYYY-MM-DD HH:mm').format('HH:mm')
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings',
      currentUser: 'auth/currentUser'
    }),

    dateStart() {
      return this.appointmentDay + ' ' + this.timeStart + ':00';
    },

    dateEnd() {
      return this.appointmentDay + ' ' + this.timeEnd + ':00';
    },

    pickerOptions() {
      return {
        start: this.$options.filters.padStart(this.settings?.callcenter_calendar_start, 2) + ':00',
        end: this.$options.filters.padStart(this.settings?.callcenter_calendar_end, 2) + ':00',
        step: '00:30',
      }
    },
  },
  methods: {
    getModalData() {
      return {
        date_start: this.dateStart,
        date_end: this.dateEnd,
        text: this.text,
        user_id: this.currentUser.id
      }
    }
  }
}
</script>

<style scoped>

</style>
