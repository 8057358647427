<template>
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.notes')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1" v-model="dealDateLocal.text" type="text">
          </base-input>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.location')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              clearable
              class="select-default text-uppercase"
              value-key="code"
              :placeholder="$t('common.type_to_search')"
              v-model="dealDateLocal.location"
            >
              <el-option
                v-for="location in dealDateLocations"
                class="select-default text-uppercase"
                :value="location"
                :label="$t('deal.appointment_locations.' + location)"
                :key="location"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.sale')" :label-width="labelWidth">
        <template slot="value">
          {{ users[dealDateLocal.user_id] | optional('lastname') }}
          {{ users[dealDateLocal.user_id] | optional('firstname') }}
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.date_start')" :label-width="labelWidth">
        <template slot="value">
          {{ appointmentDay | date }}
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.time_start')" :label-width="labelWidth">
        <template slot="value">
          {{ timeStart | time }}
        </template>
      </list-group-item-component>
    </ul>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {mapGetters} from "vuex";
import {TimeSelect, Select, Option, DatePicker} from "element-ui"
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import DealDate from "@/models/dealDate";
import DealDateLocations from "./resources/dealDateLocations";

export default {
  name: "DealDateEditModal",
  components: {
    BaseTextArea,
    ListGroupItemComponent,
    ListGroupTitleSectionComponent,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    dealDate: {
      type: Object,
      default: () => this.$_.cloneDeep(DealDate)
    }
  },
  data() {
    return {
      dealDateLocal: DealDate,
      timeStart: '',
      appointmentDay: '',
      labelWidth: 140,
      dealDateLocations: DealDateLocations
    }
  },
  mounted() {
    this.dealDateLocal = this.$_.cloneDeep(this.dealDate);
    this.appointmentDay = this.$moment(this.dealDate?.date_start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
    this.timeStart = this.$moment(this.dealDate?.date_start, 'YYYY-MM-DD HH:mm').format('HH:mm');
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings',
      users: 'common/users'
    }),

  },
  methods: {
    getDealDate() {
      return this.dealDateLocal;
    }
  }
}
</script>

<style scoped>

</style>
