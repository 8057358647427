<template>
  <div class="row justify-content-center">
    <div class="col-md-6" v-if="otherDealDatesOrdered.length > 0">
      <card class="card shadow mb-2" body-classes="standard-card-body">
        <template slot="header">
          <h5 class="card-category mb-0 text-uppercase">{{ $t('deal.appointment_history') }}</h5>
        </template>
        <card v-for="(item, index) in otherDealDatesOrdered" v-bind:key="index"
              class="card mb-0" body-classes="standard-card-body">
          <div class="d-flex align-items-center">
            <octo-icon
              :class="isPast(item.date_start) ? 'text-success' : 'text-warning'"
              :icon="isPast(item.date_start) ? 'check' : 'circle'" class="icon-deal-date"></octo-icon>
            <div class="ml-3 text-left flex-grow-1">
              <div class="text-uppercase">
                {{ users[item.user_id] | optional('firstname') }}
                {{ users[item.user_id] | optional('lastname') }}
              </div>
              <div>
                {{ item.date_start | date }} <span class="small">{{ item.date_start | time }} - {{
                  item.date_end | time
                }}</span>
              </div>
            </div>
            <div class="text-center mx-3 small text-uppercase" v-if="item.location">
              <label-theme-component>{{$t('deal.appointment_locations.' + item.location)}}</label-theme-component>
            </div>

            <base-button @click="openModal(item)" icon link class="ml-auto">
              <octo-icon icon="edit"/>
            </base-button>
          </div>
        </card>
      </card>
    </div>
    <div class="col-md-6" v-if="dealDate.id">
      <card class="card shadow mb-2" body-classes="standard-card-body">
        <template slot="header">
          <h5 class="card-category mb-0 text-uppercase">{{ appointmentText || $t('deal.new_appointment') }}</h5>
        </template>
        <card class="card mb-0" body-classes="standard-card-body">
          <div class="d-flex align-items-center">
            <octo-icon
              :class="isPast(dealDate.date_start) ? 'text-success' : 'text-muted'"
              :icon="isPast(dealDate.date_start) ? 'check' : 'circle'" class="icon-deal-date"></octo-icon>
            <div class="text-center ml-3">
              {{ dealDate.date_start | date }}
              <div class="small">{{ dealDate.date_start | time }} - {{ dealDate.date_end | time }}</div>
            </div>
            <div class="ml-2 text-uppercase">
              {{ users[dealDate.user_id] | optional('firstname') }}
              {{ users[dealDate.user_id] | optional('lastname') }}
            </div>
            <base-button @click="openModal(dealDate)" icon link class="ml-auto">
              <octo-icon icon="edit"/>
            </base-button>
          </div>
        </card>
      </card>
    </div>
    <modal bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('deal.edit_appointment') }}
      </h5>
      <deal-date-edit-modal :deal-date="selectedDealDate" :key="modalKey" ref="dealDateEditModal"/>
      <template slot="footer">
        <base-button link @click="updateDealDate">
          <span class="text-uppercase">{{ $t('common.update') }}</span>
        </base-button>
        <base-button link @click="deleteDealDate" type="danger">
          <span class="text-uppercase">{{ $t('common.delete') }}</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import DealDate from "@/models/dealDate";
import {mapGetters} from "vuex";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "../Modal";
import DealDateEditModal from "./DealDateEditModal";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "DealDateSchedulerAppointment",
  components: {LabelThemeComponent, DealDateEditModal, Modal, OctoIcon},
  props: {
    appointmentText: {
      type: String,
      default: ''
    },
    dealDate: {
      type: Object,
      default: () => this.$_.cloneDeep(DealDate)
    },
    otherDealDates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedDealDate: DealDate,
      modalKey: 0,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
    }),
    otherDealDatesOrdered() {
      return this.$_.orderBy(this.otherDealDates, ['date_start'])
    }
  },
  methods: {
    isPast(date) {
      return this.$moment(date).isBefore();
    },

    openModal(dealDate) {
      this.selectedDealDate = this.$_.cloneDeep(dealDate);
      this.modalKey++;
      this.showModal = true;
    },

    updateDealDate() {
      const dealDate = this.$refs.dealDateEditModal.getDealDate();
      this.$emit('onUpdate', dealDate);
      this.showModal = false;
    },

    deleteDealDate() {
      this.showModal = false;
      this.$emit('onDelete', this.selectedDealDate)
    }
  }
}
</script>

<style scoped lang="scss">
.icon-deal-date {
  font-size: 1.5em;
}
</style>
