<template>
  <octo-table
    :enable-store="false"
    :action="endpoints.DATATABLES.dealsInProgress"
    :fields="fields"
    :filters="filters"
    name="DealsInProgressDataTable"
    @onResetFilters="resetFilters"
  >

    <template v-slot:registry="data">
      {{ data.row.registry | optional('surname') }}
      {{ data.row.registry | optional('name') }}
    </template>

    <template v-slot:status="data">
      <badge  v-if="data.row.status" :style="{backgroundColor: schedulerService.getDealStatusColor(data.row.status)}" class="text-uppercase">
        {{ $t('deal.status.' + data.row.status) }}
      </badge>
      <div v-if="data.row.sub_status" class="small text-uppercase text-truncate">
        {{$t('deal.status.' + data.row.sub_status)}}
      </div>
    </template>

    <template v-slot:date="data">
      <div class="d-flex align-items-center justify-content-center small">
        {{data.row.date_in | date}}
        <div class="mx-2" v-if="data.row.date_out"> <octo-icon icon="right-arrow"/> </div>
        <div v-if="data.row.date_out">{{data.row.date_out | date}}</div>
      </div>
    </template>

    <template v-slot:appointments="data">
      <div v-if="data.row.lastDealDate" class="small">
        {{data.row.lastDealDate.date_start | datetime}}
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
          <base-button class="mx-1" link size="sm" icon
                       @click="$router.push({name: 'deals.in_progress.show', params: {id: data.row.id}})">
            <octo-icon icon="right-arrow"/>
          </base-button>
      </div>
    </template>

    <template slot="custom-filters">

    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "DealsInProgressDatatable",
  components: {Badge, OctoIcon, OctoTable},
  data() {
    return {
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'registry', label: 'lead', slot: true, align: 'left'},
        {prop: 'status', label: 'deal_status', slot: true, align: 'center', width: 180},
        {prop: 'date', label: 'date', slot: true, align: 'center'},
        {prop: 'appointments', label: 'appointments', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', width: 100, slot: true},
      ],
      schedulerService: schedulerService
    }
  },
  methods: {
    resetFilters: function () {
      this.filters = {

      };
    },
  }
}
</script>

<style scoped>

</style>
