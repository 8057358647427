<template>
  <div class="d-flex justify-content-center flex-wrap">
    <div class="d-flex align-items-center m-2" v-for="(item, index) in statusData" v-bind:key="index">
      <div class="rounded p-2" :style="{backgroundColor:item.color}"></div>
      <div class="ml-2 text-body" v-if="item.id">{{ $t('deal.status.' + item.id) }}</div>
    </div>
  </div>
</template>

<script>
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "DealDateSchedulerLegend",
  data() {
    return {
      statusData: schedulerService.statusData,
    }
  }
}
</script>

<style scoped>

</style>
