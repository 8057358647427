<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-6 pl-0">
        <ul class="list-group list-group-flush">
          <list-group-title-section-component :label="$t('common.registry')"/>
          <list-group-item-component
            :label="$t('fields.lastname')"
            :value="`${$options.filters.optional(deal, 'registry.surname')}`"
          />
          <list-group-item-component
            :label="$t('fields.firstname')"
            :value="`${$options.filters.optional(deal, 'registry.name')}`"
          />
          <list-group-item-component
            :label="$t('fields.birth_date')"
            :value="`${$options.filters.date($options.filters.optional(deal, 'registry.birth_date'))}`"
          />
          <list-group-item-component
            :label="$t('fields.gender')"
            :value="`${$options.filters.optional(deal, 'registry.gender')}`"
          />
          <list-group-item-component
            :label="$t('fields.codfis')"
            :value="`${$options.filters.optional(deal, 'registry.codfis')}`"
          />
        </ul>
      </div>
      <div class="col col-6 pr-0">
        <ul class="list-group list-group-flush">
          <template v-if="deal.registry && deal.registry.addresses.length !== 0">
            <list-group-title-section-component :label="$t('page.addresses')"/>
            <list-group-item-component
              v-for="(address, index) in deal.registry.addresses" v-bind:key="`address.${index}`"
            >
              <template slot="label">
                <div class="standard-label">
                  {{ address.street_address }}<br>
                  {{ address.postal_code }} {{ address.locality }} ({{ address.administrative_area_level_2 }})
                </div>
              </template>
            </list-group-item-component>
          </template>
          <template v-if="deal.registry && deal.registry.phones.length !== 0">
            <list-group-title-section-component :label="$t('page.phones')"/>
            <list-group-item-component
              v-for="(phone, index) in deal.registry.phones" v-bind:key="`phone.${index}`"
              :label="phone.label"
              :value="phone.phone"
            />
          </template>
          <template v-if="deal.registry && deal.registry.emails.length !== 0">
            <list-group-title-section-component :label="$t('page.emails')"/>
            <list-group-item-component
              v-for="(email, index) in deal.registry.emails" v-bind:key="`email.${index}`"
              :label="email.label"
              :value="email.email"
            />
          </template>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Deal from "@/models/deal";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "DealModalContent",
  components: {ListGroupItemComponent, ListGroupTitleSectionComponent},
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    }
  }
}
</script>

<style scoped>

</style>
