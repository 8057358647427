<template>
  <div>
    <deal-date-scheduler-legend/>
    <DxScheduler
      :time-zone="schedulerOptions.timeZone"
      :cell-duration="schedulerOptions.cellDuration"
      :start-day-hour="schedulerService.getStartDayHour()"
      :end-day-hour="23.5"
      :date-serialization-format="schedulerOptions.dateSerializationFormat"
      :maxAppointmentsPerCell="schedulerOptions.maxAppointmentsPerCell"
      :groups="schedulerOptions.groups"
      :scrolling="schedulerOptions.scrolling"
      :first-day-of-week="1"
      :views="['week']"
      :show-all-day-panel="false"
      current-view="week"
      :current-date="currentDate"
      :data-source="dataSource"
      :show-current-time-indicator="schedulerOptions.showCurrentTimeIndicator"
      :editing="editing"
      :min="dateMin.toDate()"
      :onAppointmentAdded="(event) => event.cancel = true"
      :onAppointmentAdding="(event) => event.cancel = true"
      :onAppointmentClick="appointmentClick"
      :onAppointmentContextMenu="(event) => event.cancel = true"
      :onAppointmentDblClick="(event) => event.cancel = true"
      :onAppointmentDeleted="(event) => event.cancel = true"
      :onAppointmentDeleting="(event) => event.cancel = true"
      :onAppointmentFormOpening="(event) => event.cancel = true"
      :onAppointmentRendered="(event) => event.cancel = true"
      :onOptionChanged="(event) => event.cancel = true"
      :onAppointmentUpdated="(event) => event.cancel = true"
      :on-content-ready="onContentReady"
      :onCellClick="cellClick"
      appointment-template="AppointmentTemplateSlot"
      ref="dxScheduler"
      v-observe-visibility="visibilityChanged"
    >
      <DxResource
        :allow-multiple="false"
        :data-source="statusData"
        :use-color-as-default="true"
        field-expr="status"
        label="Status"
      />

      <template #AppointmentTemplateSlot="{ data }">
        <div class="position-relative overflow-auto">
          <div v-if="!data.appointmentData.dealDate.dateable_id" class="d-flex justify-content-end">
            <base-button link icon size="sm" @click="deleteDealDate(data.appointmentData.dealDate)">
              <octo-icon icon="wrong" class="text-white"/>
            </base-button>
          </div>
          <div>{{ data | optional('appointmentData.text') }}</div>
          <div class="small">
            {{ data | optional('appointmentData.startDate') | time }} -
            {{ data | optional('appointmentData.endDate') | time }}
          </div>
        </div>
      </template>

      <slot name="DxResource"></slot>
    </DxScheduler>
    <modal bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ currentEvent.id ? $t('fields.edit_appointment_block') : $t('fields.new_appointment_block') }}
      </h5>
      <new-appointment-block-modal
        :event="currentEvent"
        :key="`appointment-block-modal-${modalKey}`"
        ref="newAppointmentBlockModal"
      />
      <template slot="footer">
        <base-button link @click="saveAppointmentBlock">
          <span class="text-uppercase">{{ $t('common.save') }}</span>
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>

import {DxResource, DxScheduler} from "devextreme-vue/scheduler";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Modal from "@/components/Modal";
import {DatePicker, Option, Select, TimeSelect} from "element-ui";
import {schedulerService} from "@/util/scheduler-service";
import DealDateSchedulerLegend from "@/components/DealDateScheduler/DealDateSchedulerLegend";
import DealDateSchedulerAppointment from "@/components/DealDateScheduler/DealDateSchedulerAppointment";
import {mapGetters} from "vuex";
import {ObserveVisibility} from "vue-observe-visibility";
import {loadMessages, locale} from "devextreme/localization";
import itMessages from "@/pages/Deals/resources/it.json";
import {endpoints} from "@/routes/endpoints";
import NewAppointmentBlockModal from "./NewAppointmentBlockModal";
import DealDate from "@/models/dealDate";
import OctoIcon from "../octo-icon/OctoIcon";

export default {
  name: "DealDateInProgressScheduler",
  components: {
    OctoIcon,
    NewAppointmentBlockModal,
    DealDateSchedulerAppointment,
    DealDateSchedulerLegend,
    ListGroupItemComponent,
    DxScheduler,
    DxResource,
    Modal,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  directives: {
    'observe-visibility': ObserveVisibility
  },
  props: {},
  data() {
    return {
      schedulerService: schedulerService,
      schedulerOptions: schedulerService.options,
      statusData: schedulerService.statusData,
      dataSource: [],
      resourcesData: [],
      currentDate: new Date(),
      scheduler: null,
      dateMin: this.$moment().subtract(60, 'days'),
      currentEvent: this.$_.cloneDeep(DealDate),
      showModal: false,
      modalKey: 1,
      editing: {
        allowAdding: true,
        allowDeleting: false,
        allowUpdating: false,
        allowResizing: false,
        allowDragging: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
  },

  created() {
    loadMessages(itMessages);
    locale('it');
  },

  beforeMount() {

    const queryParams = new URLSearchParams({
      'start': this.dateMin.format('YYYY-MM-DD')
    });

    this.$api.get(endpoints.DEAL_DATE_INDEX_BY_USER + '?' + queryParams.toString()).then((resp) => {
      this.dataSource = this.$_.map(resp?.data?.data || [], (dealDate) => {
        return this.parseDealDate(dealDate)
      }) || [];
    })
      .catch((e) => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')})
      })
      .finally(() => {

      })
    this.setEcho();
  },


  methods: {

    onContentReady(e) {
      this.scheduler = e.component;
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.$refs.dxScheduler.instance.repaint();
      }
    },

    cellClick(event) {
      event.cancel = true;

      const startHour = this.$moment(event.cellData.startDate).format('HH');

      if (startHour >= schedulerService.getEndDayHour()) {
        return;
      }

      this.currentEvent = event;
      this.modalKey++
      this.showModal = true;
    },

    appointmentClick(event) {
      event.cancel = true;

      this.currentEvent = event;

      if (!event?.appointmentData?.disabled) {
       this.$emit('onAppointmentClick', event.appointmentData)
      } else {
        this.modalKey++;
        this.showModal = true;
      }

    },

    parseDealDate(item) {
      const event = schedulerService.parseDealDate(item);
      event.disabled = !event?.dealDate?.dateable_id;
      return event;
    },

    setEcho() {
      try {

        this.$echo
          .channel(`sales-calendar-updated`)
          .listen('.sales-calendar-updated', (e) => {
            console.log('Join Channels sales-calendar-updated');
            this.handleCalendarUpdated(e);
          });

        this.$echo
          .channel(`sales-calendar-deleted`)
          .listen('.sales-calendar-deleted', (e) => {
            console.log('Join Channels sales-calendar-deleted');
            this.handleCalendarDeleted(e);
          })
      } catch (e) {
        console.log('Join Channels error', e);
      }

    },

    handleCalendarUpdated(e) {
      this.$emit('onHandleCalendarUpdated', e);
      if (e.owner_id === this.currentUser.id || e.user_id !== this.currentUser.id) {
        return;
      }

      const event = this.parseDealDate(e);
      const dataSource = this.$_.filter(this.$_.cloneDeep(this.dataSource), (item) => {
        return item.id !== event.id;
      });
      dataSource.push(event);
      this.dataSource = dataSource;
    },

    handleCalendarDeleted(e) {
      this.dataSource = this.$_.filter(this.dataSource, (item) => {
        return item.id !== e.id;
      });
    },

    saveAppointmentBlock() {
      this.$fullLoading.show();

      const dealDateId = this.currentEvent?.appointmentData?.id;

      const endpoint = dealDateId
        ? endpoints.DEAL_DATE_UPDATE.replace('{id}', dealDateId)
        : endpoints.DEAL_DATE_STORE_APPOINTMENT_BLOCK;

      this.$api({
        method: dealDateId ? 'put' : 'post',
        url: endpoint,
        data: this.$refs.newAppointmentBlockModal.getModalData()
      })
        .then((resp) => {
          const event = schedulerService.parseDealDate(resp?.data?.data);
          event.disabled = true;

          this.dataSource = this.$_.filter(this.dataSource, (item) => {
            return item.id !== event.id;
          });

          this.dataSource.push(event);
          this.scheduler.repaint();
          this.showModal = false;

          this.$notify({type: 'success', message: this.$t('notifications.saved_success')})
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    deleteDealDate(dealDate) {
      this.dataSource = this.$_.filter(this.dataSource, (item) => item.id !== dealDate.id);

      this.$api.delete(endpoints.DEAL_DATE_DELETE.replace('{id}', dealDate.id))
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.deleted_success')});
        }).catch(() => {
        this.dataSource.push(this.parseDealDate(dealDate));
        this.$notify({type: 'danger', message: this.$t('notifications.deleted_error')});
      })
    },
  },

  beforeDestroy() {
    this.$echo.leave('sales-calendar-updated');
    this.$echo.leave('sales-calendar-deleted');
  }
}
</script>

<style scoped>

</style>
