import store from '@/store'
import {endpoints} from "@/routes/endpoints";
import _ from "lodash";
import Moment from "moment";
import DealStatuses from "../pages/Deals/resources/dealStatuses";

const schedulerService = {
    statusData: [
      {
        text: 'other',
        id: 'other',
        color: DealStatuses.other.color,
        show: false
      },
      {
        text: 'not_interested',
        id: 'not_interested',
        color: DealStatuses.not_interested.color,
        show: true
      },
      {
        text: 'processing',
        id: 'processing',
        color: DealStatuses.processing.color,
        show: true
      },
      {
        text: 'confirmed',
        id: 'confirmed',
        color: DealStatuses.confirmed.color,
        show: true
      },
      {
        text: 'pending',
        id: 'pending',
        color: DealStatuses.pending.color,
        show: true
      },
      {
        text: 'recall',
        id: 'recall',
        color: DealStatuses.recall.color,
        show: true
      },
      {
        text: 'closed',
        id: 'closed',
        color: DealStatuses.closed.color,
        show: true
      },
      {
        text: 'not_valid',
        id: 'not_valid',
        color: DealStatuses.not_valid.color,
        show: true
      },
    ],

  users: store.getters['common/users'],
  settings: store.getters['common/settings'],

  options: {
    timeZone: "Europe/Rome",
    height: 580,
    cellDuration: 30,
    views: ['timelineDay'],
    groups: ['userId'],
    scrolling: {
      mode: 'standard'
    },
    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
    currentView: "timelineDay",
    maxAppointmentsPerCell: "unlimited",
    firstDayOfWeek: 0,
    showCurrentTimeIndicator: false

  },

  getStartDayHour() {
    return this.settings?.callcenter_calendar_start || 9
  },

  getEndDayHour() {
    return this.settings?.callcenter_calendar_end || 18
  },

    parseDealDate(item) {
        const operatorId = item?.dateable?.lead?.user_id || null;

        let status = item?.dateable?.status;

        if (status === 'promoted') {
          status = 'closed';
        }

        return {
            text: item.text,
            userId: item.user_id,
            operatorId: operatorId,
            operatorName: operatorId
                ? (this.users[operatorId]?.firstname + ' ' + this.users[operatorId]?.lastname)
                : 'N.D.',
            startDate: item.date_start,
            endDate: item.date_end,
            status: status,
            id: item.id,
            deal: item?.dateable,
            lead: item?.dateable?.lead,
            dealDate: item,
            checked: item?.status === 'checked',
            disabled: true,
            ownerId: item.owner_id
        }
    },

  getData(dateFrom, dateTo, axiosInstance) {

    const queryParams = new URLSearchParams({
      'start': dateFrom
    });

    if (dateTo) {
      queryParams.append('end', dateTo)
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get(endpoints.DEAL_DATE_INDEX_DIARY + '?' + queryParams.toString())
        .then((resp) => {
          resolve(resp?.data?.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },

  dayHours() {

    return {
      start: this.settings?.callcenter_calendar_start || 9,
      end: this.settings?.callcenter_calendar_end || 18,
    }
  },

  resourcesData(sales) {
      const activeSales = _.filter(sales, (sale) => sale?.active === true)
      const resourceData = _.map(activeSales, (sale) => {
        return {
          text: this.users[sale?.id]?.firstname
            + ' ' + this.users[sale?.id]?.lastname,
          order_key: this.users[sale?.id]?.lastname,
          id: sale?.id
        }
      });
    return _.orderBy(resourceData, 'order_key', 'asc');
  },

  updateDealDate: function (id, startDate, endDate, userId, axiosInstance) {
    return new Promise((resolve, reject) => {
      axiosInstance.put(
        endpoints.DEAL_DATE_CHANGE_OWNERSHIP.replace('{id}', id),
        {
          start: Moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          end: Moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
          sale_id: userId
        }
      )
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    });
  },

  pickerOptions() {
    const timeStart = this.settings?.callcenter_calendar_start || '9';
    const timeEnd = this.settings?.callcenter_calendar_end || '20';
    return {
      start: timeStart.toString().padStart(2, '0') + ':00',
      end: timeEnd.toString().padStart(2, '0') + ':00',
      step: '00:30',
    }
  },

  getModalAppointmentData(item) {
    const startDate = Moment(item.cellData.startDate);
    const endDate = _.cloneDeep(startDate);
    endDate.add(this.settings?.callcenter_appointment_duration || 90, 'minutes');

    return {
      user_id: item.cellData.groups.userId,
      day: startDate.format('YYYY-MM-DD'),
      time: startDate.format('HH:mm'),
    }
  },

  getDealStatusColor(status) {
      return DealStatuses[status]
        ? DealStatuses[status].color
        : DealStatuses.other.color;
  }
};

export {
  schedulerService
}
