const DealStatuses = {
  pending: {
    color: 'rgba(255,15,52,0.64)',
    value: 'pending',
    info: 'pending_info',
    subStatuses: [],
  },
  confirmed: {
    color: 'rgba(55,175,106,0.64)',
    value: 'confirmed',
    info: 'confirmed_info',
    subStatuses: [],
  },
  promoted: {
    color: 'rgba(12,159,255,0.64)',
    value: 'promoted',
    info: 'promoted_info',
    subStatuses: [],
  },
  accounting_rejected: {
    color: '',
    value: 'accounting_rejected',
    info: 'accounting_rejected_info',
    subStatuses: [
      'regular_withdrawal',
      'irregular_withdrawal',
      'no_fee_payment',
      'no_first_instalment_payment'
    ],
  },

  closed: {
    color: 'rgba(12,159,255,0.64)',
    value: 'closed',
    info: 'closed_info',
    subStatuses: [
      'closed_in_first_instance',
      'closed_in_second_instance'
    ],
  },
  processing: {
    color: 'rgba(252,166,152,0.64)',
    value: 'processing',
    info: 'processing_info',
    subStatuses: [
      'no_answer_immediately',
      'has_to_talk_to_family',
      'must_evaluate_if_can_attend',
      'wants_to_evaluate_other_options',
      'enrollment_procedure_activated',
      'wants_to_reason_about_choice',
      'closed_enrollment_procedure_activated'
    ],
  },
  recall: {
    color: 'rgba(255,170,0,0.64)',
    value: 'recall',
    info: 'recall_info',
    subStatuses: [
      'appointment_not_confirmed',
      'absent',
      'sales_inconvenience',
      'client_inconvenience',
      'misunderstanding_date_and_time',
      'has_to_talk_to_family',
      'wants_to_think_about_choice_to_make'
    ],
  },
  not_interested: {
    color: 'rgba(167,51,184,0.64)',
    value: 'not_interested',
    info: 'not_interested_info',
    subStatuses: [
      'wants_to_evaluate_other_schools',
      'no_e_learning_only_classroom',
      'does_not_like_teaching_plan',
      'wants_shorter_group_period',
      'individual_course_too_expensive',
      'price_too_high',
      'financial_problems',
      'interested_in_recalling_later',
      'indecisive_little_motivation',
      'distrustful',
      'just_curiosity',
      'personal_hobby',
      'too_busy_recall_later',
      'prefers_classroom_and_wants_to_think',
      'wants_to_attend_base_course',
      'interested_but_covid_19',
      'interested_but_not_now_later',
      'decided_to_attend_university',
      'interested_in_advanced_course',
      'interested_but_not_now',
      'has_chosen_another_school',
      'does_not_like_formative_plan',
      'wants_different_formative_plan',
      'formative_plan_too_long',
      'individual_proposal_price_too_high'
    ],
  },
  not_valid: {
    color: 'rgba(87,86,86,0.64)',
    value: 'not_valid',
    info: 'not_valid_info',
    subStatuses: [
      'no_criterion_for_consulting',
      'not_interested_anymore',
      'distrustful_people',
      'changed_idea',
      'client_still_absent_and_unreachable',
      'searches_different_course',
    ],
  },
  not_confirmed: {
    color: 'rgba(87,86,86,0.64)',
    value: 'not_confirmed',
    info: 'not_confirmed_info',
    subStatuses: [],
  },
  other: {
    color: 'rgba(185,185,185,0.39)',
    value: 'other',
  }
}

export default DealStatuses
